<template>
  <div>
    <the-admin-layout-header :headline="headline" />

    <v-card width="950" class="ml-15 mt-7" outlined>
      <v-card color="transparent" flat>
        <v-row no-gutters>
          <v-col cols="3" class="text-right">
            <v-card color="transparent" flat>
              <v-card-text class="headline primary--text">
                Personal
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3"
            ><v-btn
              @click="dialogPersonal = !dialogPersonal"
              small
              text
              depressed
              class="mt-4"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >EDIT</v-btn
            >
          </v-col>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text  mt-n6">
              Name:
            </v-card-text>
          </v-col>
          <v-col cols="3"
            ><v-card-text class="subtitle-1 mt-n6">
              {{ profile.title }}
              {{ profile.firstName }}
              {{ profile.familyName }}
            </v-card-text></v-col
          >
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"
            ><v-card color="transparent" flat>
              <v-card-text class="headline primary--text mt-3">
                Address
              </v-card-text>
            </v-card></v-col
          >
          <v-col cols="3"
            ><v-btn
              @click="dialogAddress = !dialogAddress"
              small
              text
              depressed
              class="mt-7"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >edit</v-btn
            >
          </v-col>
          <v-col cols="3" class="text-right"
            ><v-card color="transparent" flat>
              <v-card-text class="headline primary--text mt-3">
                Contact
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3"
            ><v-btn
              @click="dialogContact = !dialogContact"
              small
              text
              depressed
              class="mt-7"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >edit</v-btn
            >
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right mt-n6">
            <v-card-text class="subtitle-1 grey--text">
              Residential:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n6">
              {{ profile.resNumber }}
              {{ profile.resStreet }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n6">
              Email address:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n6">
              {{ profile.email }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.resSuburb }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Mobile phone:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.mobilePhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.resCity }}
              {{ profile.resPostcode }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Work phone:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.workPhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.resCountry }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Home phone:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.homePhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n4">
              Mailing:
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n4">
              {{ profile.mailNumber }}
              {{ profile.mailStreet }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.mailSuburb }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.mailCity }}
              {{ profile.mailPostcode }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3">
            <v-card-text class="subtitle-1 mt-n8">
              {{ profile.mailCountry }}
            </v-card-text>
          </v-col>
          <v-col cols="3" class="text-right"> </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn text class="primary--text" @click="cancel">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <v-dialog persistent v-model="dialogPersonal" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update personal details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="profile.title"
                    :items="titleItems"
                    label="Title"
                  ></v-select>
                </v-col>
                <v-col cols="8"> </v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profile.firstName"
                    label="First name"
                    :rules="nameReqRules"
                /></v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profile.familyName"
                    label="Family name"
                    :rules="nameReqRules"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn text color="success" :disabled="!valid" @click="update"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogAddress" width="500px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update address details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card-text class="title ml-n3">
                    Residential
                  </v-card-text></v-col
                >
                <v-col cols="6">
                  <v-card-text class="title ml-n3">
                    Mailing
                  </v-card-text>
                  <v-btn
                    @click="copyResidential"
                    color="success"
                    text
                    small
                    class="mt-n5"
                  >
                    (copy residental)</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="mt-n4">
                  <v-text-field
                    v-model="profile.resNumber"
                    :rules="numberRules"
                    label="Number"
                  />
                  <v-text-field
                    v-model="profile.resStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profile.resSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profile.resCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profile.resPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profile.resCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <v-text-field
                    v-model="profile.mailNumber"
                    :rules="numberRules"
                    label="Number"
                  />
                  <v-text-field
                    v-model="profile.mailStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profile.mailSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profile.mailCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profile.mailPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profile.mailCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn text color="success" :disabled="!valid" @click="update"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogContact" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update contact details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="profile.email"
                    label="Email"
                    :rules="emailReqRules"
                    prepend-icon="mdi-email"
                  />
                  <v-text-field
                    v-model="profile.mobilePhone"
                    label="Mobile phone"
                    :rules="phoneReqRules"
                    prepend-icon="mdi-phone"
                  />
                  <v-text-field
                    v-model="profile.workPhone"
                    label="Work phone"
                    :rules="phoneRules"
                    prepend-icon="mdi-phone"
                  />
                  <v-text-field
                    v-model="profile.homePhone"
                    label="Home phone"
                    :rules="phoneRules"
                    prepend-icon="mdi-phone"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn text color="success" :disabled="!valid" @click="update"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheAdminLayoutHeader from '@/components/TheAdminLayoutHeader.vue'

export default {
  name: 'adminAdvisorProfile',

  components: {
    TheAdminLayoutHeader
  },

  data() {
    return {
      valid: true,
      dialogPersonal: false,
      dialogAddress: false,
      dialogContact: false,
      titleItems: ['Mr', 'Mrs', 'Ms', 'Miss'],
      nameRules: [
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberRulesCode: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      phoneRules: [
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      phoneReqRules: [
        v => !!v || 'Phone is required',
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      emailReqRules: [
        v => !!v,
        v => !v || (!!v && /.+@.+\..+/.test(v)) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    copyResidential() {
      this.profile.mailNumber = this.profile.resNumber
      this.profile.mailStreet = this.profile.resStreet
      this.profile.mailSuburb = this.profile.resSuburb
      this.profile.mailCity = this.profile.resCity
      this.profile.mailPostcode = this.profile.resPostcode
      this.profile.mailCountry = this.profile.resCountry
    },

    update() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateAdvisor', this.adminAdvisorId).then(() => {
          this.dialogPersonal = false
          this.dialogContact = false
          this.dialogAddress = false
        })
      }
    },

    close() {
      this.$store.dispatch('fetchAdvisor', this.adminAdvisorId).then(() => {
        this.dialogPersonal = false
        this.dialogContact = false
        this.dialogAddress = false
      })
    },

    cancel() {
      this.$router.push('/admin/advisors')
    }
  },

  computed: {
    headline() {
      return 'Accounts / Profile / ' + this.profile.name
    },

    profile: {
      get() {
        return this.adminAdvisorProfile.profile
      },
      set(attributes) {
        this.$store.commit('SET_ADMIN_ADVISOR_PROFILE', attributes)
      }
    },

    ...mapState(['adminAdvisorProfile']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
